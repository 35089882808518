<template>
    <v-list-item
        class="d-flex align-center justify-center"
    >
        <span class="caption">{{ $trans('No data to display') }}</span>
    </v-list-item>
</template>

<script>
export default {
    name: 'MediaTableEmptyContent'
}
</script>

<style lang=scss>

</style>
